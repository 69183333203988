<template>
  <div class="grid grid-cols-12 gap-6 mt-5">
    <CreateBar />
    <BarsList />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import CreateBar from "@/components/bars/CreateBar.vue";
import BarsList from "@/components/bars/BarsList.vue";

export default defineComponent({
    name: 'Bars',
    components: {
        CreateBar,
        BarsList
    },
    mounted: async function() {
        this.$store.commit('updateTopBarTitle', this.$i18n.t('menu.bars'))
    },
})
</script>
