<template>
  <div
    class="grid grid-cols-12 gap-3 px-5 py-3 items-center relative"
    :class="{ 'bg-gray-200 dark:bg-dark-1': index % 2 == 0 }"
  >
    <LoadingBlock v-if="processing" />
    <div class="col-span-12 xl:col-span-2">
      <div class="xl:hidden text-gray-500 text-sm">{{ $t('views.bars.barsList.depository') }}:</div>
      <div class="">{{ bar.denom }}</div>
    </div>
    <div class="col-span-12 xl:col-span-2">
      <div class="xl:hidden text-gray-500 text-sm">{{ $t('views.bars.barsList.barNo') }}:</div>
      <a href="" class="font-medium whitespace-nowrap">{{ bar.number }}</a>
      <!-- <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">BLBALBA</div> -->
    </div>
    <div class="col-span-12 xl:col-span-2">
      <div class="xl:hidden text-gray-500 text-sm">{{ $t('views.bars.barsList.brandMark') }}:</div>
      {{ bar.brand }}
    </div>
    <div class="col-span-12 xl:col-span-1">
      <div class="xl:hidden text-gray-500 text-sm">{{ $t('views.bars.barsList.noOfPieces') }}:</div>
      {{ bar.pieces }}
    </div>
    <div class="col-span-12 xl:col-span-1">
      <div class="xl:hidden text-gray-500 text-sm">
        {{ $t('views.bars.barsList.barWeight') }} <span class="text-sm text-gray-500">{{ $t('views.bars.barsList.inGrams') }}</span>:
      </div>
      {{ bar.weight }}
    </div>
    <div class="col-span-12 xl:col-span-1">
      <div class="xl:hidden text-gray-500 text-sm">
        {{ $t('views.bars.barsList.barFineWeight') }} <span class="text-sm text-gray-500">{{ $t('views.bars.barsList.inGrams') }}</span>:
      </div>
      {{ bar.fine_weight }}
    </div>
    <div class="col-span-12 xl:col-span-3 flex items-center">
      <div class="ml-auto">
        <a
          v-if="bar.uri"
          :href="bar.uri"
          target="_blank"
          class="btn btn-secondary w-32"
        >
          <ExternalLinkIcon class="w-4 h-4 mr-2" /> {{ $t('views.bars.barItem.certificate') }}
        </a>
        <button
          v-if="operator"
          v-on:click="withdrawBar"
          class="btn btn-danger w-32 ml-2"
        >
          <TrashIcon class="w-4 h-4 mr-2" /> {{ $t('views.bars.barItem.withdraw') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Coin from "@/utils/coin";
import BigNumber from "bignumber.js";
import Constants from "@/utils/constants";
import LoadingBlock from '@/components/common/LoadingBlock.vue'

export default defineComponent({
  name: "BarItem",
  components: {
    LoadingBlock
  },
  props: {
    bar: Object,
    address: String,
    operator: Boolean,
    index: Number,
  },
  data: function () {
    return {
      processing: false,
    };
  },
  methods: {
    withdrawBar: async function () {
      if (confirm(this.$i18n.t('views.bars.barItem.withdrawConfirmation'))) {
        try {
          const value = {
            operator: this.address,
            number: this.bar.number,
            brand: this.bar.brand,
          };
          this.processing = true;

          const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
          const simulateResult = await this.$store.dispatch(
            "metdeal.cryptounce.depository.v1/sendMsgWithdrawBarSimulate",
            {
              value,
              fee: [{ amount: "0", denom: Constants.DEFAULT_CURRENCY }],
              memo: "",
            }
          );
          const gas = simulateResult.calculatedGas;

          let baseAmount = BigNumber(gas)
            .multipliedBy(BigNumber(Constants.GAS_PRICE))
            .multipliedBy(BigNumber(10).exponentiatedBy(coin.denomExponent()));
          baseAmount = baseAmount.integerValue(BigNumber.ROUND_CEIL);
          const fee = {
            amount: baseAmount.toString(),
            denom: Constants.DEFAULT_CURRENCY,
          };

          const txResult = await this.$store.dispatch(
            "metdeal.cryptounce.depository.v1/sendMsgWithdrawBar",
            {
              value: value,
              fee: [fee],
              memo: "",
              gas: gas.toString(),
            }
          );

          if (txResult && !txResult.code) {
            this.$toast.success(this.$i18n.t('views.bars.notificationSuccessBarWithdrawed'))
            await this.$store.dispatch(
              "metdeal.cryptounce.depository.v1/QueryBars",
              { options: {} },
              {}
            );
          }
        } catch (e) {
          this.$toast.error(this.$i18n.t('common.errorNotification'))
          console.error(e);
        } finally {
          this.processing = false;
        }
      }
    },
  },
});
</script>
