<template>
  <div class="col-span-12">
    <div class="intro-y box">
      <div
        class="
          flex flex-col
          sm:flex-row
          items-center
          p-5
          border-b border-gray-200
          dark:border-dark-5
        "
      >
        <h2 class="font-medium text-base mr-auto">{{ $t('views.bars.barsList.title') }}</h2>
      </div>

      <div class="grid grid-cols-12 gap-6 my-5 px-5">
        <div class="hidden xl:block col-span-12 xl:col-span-2 uppercase">{{ $t('views.bars.barsList.depository') }}</div>
        <div class="hidden xl:block col-span-12 xl:col-span-2 uppercase">{{ $t('views.bars.barsList.barNo') }}</div>
        <div class="hidden xl:block col-span-12 xl:col-span-2 uppercase">{{ $t('views.bars.barsList.brandMark') }}</div>
        <div class="hidden xl:block col-span-12 xl:col-span-1 uppercase">
          {{ $t('views.bars.barsList.noOfPieces') }}
        </div>
        <div class="hidden xl:block col-span-12 xl:col-span-1 uppercase">
          {{ $t('views.bars.barsList.barWeight') }} <span class="block text-sm text-gray-500 lowercase">{{ $t('views.bars.barsList.inGrams') }}</span>
        </div>
        <div class="hidden xl:block col-span-12 xl:col-span-1 uppercase">
          {{ $t('views.bars.barsList.barFineWeight') }} <span class="block text-sm text-gray-500 lowercase">{{ $t('views.bars.barsList.inGrams') }}</span>
        </div>
        <div class="hidden xl:block col-span-12 xl:col-span-3 text-right uppercase">
          {{ $t('views.bars.barsList.certUrlActions') }}
        </div>
      </div>

      <BarItem
        v-for="(bar, index) in bars"
        v-bind:key="index"
        :index="index"
        :bar="bar"
        :operator="operator"
        :address="address"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import BigNumber from "bignumber.js";
import BarItem from "./BarItem.vue";
import Constants from "@/utils/constants";

export default defineComponent({
  name: "BarsList",
  data() {
    return {
      address: "",
    };
  },
  components: {
    BarItem,
  },
  mounted: async function () {
    this.address = this.$store.getters["common/wallet/address"];
    await this.$store.dispatch(
      "metdeal.cryptounce.depository.v1/QueryBars",
      { options: {} },
      {}
    );
  },
  computed: {
    operator: function () {
      const depository = this.$store.getters[
        "metdeal.cryptounce.depository.v1/getDepository"
      ]({ params: { denom: Constants.DEFAULT_CURRENCY } });
      return this.address == depository?.depository?.operator;
    },
    bars: function () {
      const barsRes = this.$store.getters[
        "metdeal.cryptounce.depository.v1/getBars"
      ]({ params: {} });
      return (
        barsRes?.bars?.map(function (b) {
          return {
            denom: b.denom,
            number: b.number,
            brand: b.brand,
            pieces: b.pieces,
            weight: BigNumber(b.weight).toFixed(2),
            fine_weight: BigNumber(b.fine_weight).toFixed(2),
            uri: b.uri,
          };
        }) || []
      );
    },
  },
});
</script>

