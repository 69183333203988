<template>
  <div class="col-span-12 xl:col-span-6" v-if="isOperator">
    <div class="intro-y box">
      <div
        class="
          flex flex-col
          sm:flex-row
          items-center
          p-5
          border-b border-gray-200
          dark:border-dark-5
        "
      >
        <h2 class="font-medium text-base mr-auto">{{ $t('views.bars.createBar.title') }}</h2>
      </div>
      <div class="p-5 relative">
        <LoadingBlock v-if="processing" />
        <div class="">
          <div class="mt-3">
            <label for="form-input-1" class="form-label">{{ $t('views.bars.createBar.barNo') }}</label>
            <input
              id="form-input-1"
              type="text"
              class="form-control"
              v-on:keypress="$h.onlyFloat"
              v-model="deposit.barNo"
              :class="{ 'border-theme-6': validations.visible && validations.barNo }"
            />
            <div class="text-theme-6 mt-2" v-if="validations.visible && validations.barNo">{{ validations.barNo }}</div>
          </div>

          <div class="mt-3">
            <label for="form-input-2" class="form-label">{{ $t('views.bars.createBar.brandMark') }}</label>
            <input
              id="form-input-2"
              type="text"
              class="form-control"
              v-model="deposit.brandMark"
              :class="{ 'border-theme-6': validations.visible && validations.brandMark }"
            />
            <div class="text-theme-6 mt-2" v-if="validations.visible && validations.brandMark">{{ validations.brandMark }}</div>
          </div>

          <div class="mt-3">
            <label for="form-input-3" class="form-label">{{ $t('views.bars.createBar.noOfPieces') }}</label>
            <input
              id="form-input-3"
              type="text"
              class="form-control"
              v-on:keypress="$h.onlyFloat"
              v-model="deposit.noOfPieces"
              :class="{ 'border-theme-6': validations.visible && validations.noOfPieces }"
            />
            <div class="text-theme-6 mt-2" v-if="validations.visible && validations.noOfPieces">{{ validations.noOfPieces }}</div>
          </div>

          <div class="mt-3">
            <label for="form-input-4" class="form-label"
              >{{ $t('views.bars.createBar.barWeight') }}</label
            >
            <input
              id="form-input-4"
              type="text"
              class="form-control"
              v-on:keypress="$h.onlyFloat"
              v-model="deposit.barWeight"
              :class="{ 'border-theme-6': validations.visible && validations.barWeight }"
            />
            <div class="text-theme-6 mt-2" v-if="validations.visible && validations.barWeight">{{ validations.barWeight }}</div>
          </div>

          <div class="mt-3">
            <label for="form-input-5" class="form-label">{{ $t('views.bars.createBar.barFineWeight') }}</label>
            <input
              id="form-input-5"
              type="text"
              class="form-control"
              v-on:keypress="$h.onlyFloat"
              v-model="deposit.fineWeight"
              :class="{ 'border-theme-6': validations.visible && validations.fineWeight }"
            />
            <div class="text-theme-6 mt-2" v-if="validations.visible && validations.fineWeight">{{ validations.fineWeight }}</div>
          </div>

          <div class="mt-3">
            <label for="form-input-6" class="form-label">{{ $t('views.bars.createBar.certificateUrl') }}</label>
            <input
              id="form-input-6"
              type="text"
              class="form-control"
              v-model="deposit.url"
            />
          </div>

          <div class="mt-5 flex flex-row-reverse">
            <button
              class="btn btn-primary w-34 mr-1 mb-2"
              v-on:click="createBar"
            >
              {{ $t('views.bars.createBar.deposit') }}
            </button>
            <button
              class="btn btn-secondary w-24 mr-1 mb-2"
              v-on:click="resetDeposit"
            >
              {{ $t('views.bars.createBar.reset') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Coin from "@/utils/coin";
import BigNumber from "bignumber.js";
import Constants from "@/utils/constants";
import LoadingBlock from '@/components/common/LoadingBlock.vue'

export default defineComponent({
  name: "CreateBar",
  components: {
    LoadingBlock
  },
  data: function () {
    return {
      deposit: {
        barNo: "",
        brandMark: "",
        noOfPieces: "",
        barWeight: "",
        fineWeight: "",
        url: "",
        denom: Constants.DEFAULT_CURRENCY,
      },
      validations: {
        barNo: "",
        brandMark: "",
        noOfPieces: "",
        barWeight: "",
        fineWeight: "",
        visible: false
      },
      address: "",
      gas: 0,
      fee: { amount: "0", denom: Constants.DEFAULT_CURRENCY },
      processing: false,
    };
  },
  computed: {
    operator: function () {
      const depository = this.$store.getters[
        "metdeal.cryptounce.depository.v1/getDepository"
      ]({ params: { denom: Constants.DEFAULT_CURRENCY } });
      return depository?.depository?.operator;
    },
    isOperator: function () {
      return this.address == this.operator;
    },
  },
  mounted: function () {
    this.address = this.$store.getters["common/wallet/address"];
  },
  methods: {
    validate: function(visible = false) {
      this.validations.barNo = isNaN(parseInt(this.deposit.barNo)) ? this.$i18n.t('views.bars.createBar.validations.barNo') : ''
      this.validations.brandMark = !this.deposit.brandMark ? this.$i18n.t('views.bars.createBar.validations.brandMark') : ''
      this.validations.noOfPieces = isNaN(parseInt(this.deposit.noOfPieces)) ? this.$i18n.t('views.bars.createBar.validations.noOfPieces') : ''
      this.validations.barWeight = isNaN(parseFloat(this.deposit.barWeight)) ? this.$i18n.t('views.bars.createBar.validations.barWeight') : ''
      this.validations.fineWeight = isNaN(parseFloat(this.deposit.fineWeight)) ? this.$i18n.t('views.bars.createBar.validations.barFineWeight') : ''
      this.validations.visible = visible
      return !(this.validations.barNo || this.validations.brandMark || this.validations.noOfPieces || this.validations.barWeight || this.validations.fineWeight)
    },
    createBar: async function () {
      if (this.validate(true) && !this.processing) {
        try {
          this.processing = true;
          await this.calculateFee();
          if (this.gas > 0 && BigNumber(this.fee.amount).isGreaterThan(0)) {
            const precision = BigNumber(10).exponentiatedBy(18);
            const value = {
              operator: this.address,
              denom: Constants.DEFAULT_CURRENCY,
              number: this.deposit.barNo,
              brand: this.deposit.brandMark,
              pieces: this.deposit.noOfPieces,
              weight: BigNumber(this.deposit.barWeight)
                .multipliedBy(precision)
                .toFixed(),
              fineWeight: BigNumber(this.deposit.fineWeight)
                .multipliedBy(precision)
                .toFixed(),
              uri: this.deposit.url,
            };

            const txResult = await this.$store.dispatch(
              "metdeal.cryptounce.depository.v1/sendMsgDepositBar",
              {
                value: value,
                fee: [this.fee],
                memo: "",
                gas: this.gas.toString(),
              }
            );

            if (txResult && !txResult.code) {
              this.$toast.success(this.$i18n.t('views.bars.notificationSuccessBarDeposited'))
              this.resetDeposit()
              await this.$store.dispatch(
                "metdeal.cryptounce.depository.v1/QueryBars",
                { options: {} },
                {}
              );
            }
          }
        } catch (e) {
          this.$toast.error(this.$i18n.t('common.errorNotification'))
          console.error(e);
        } finally {
          this.processing = false;
        }
      }
    },
    calculateFee: async function () {
      if (this.validate(false)) {
        const precision = BigNumber(10).exponentiatedBy(18);
        const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
        const value = {
          operator: this.address,
          denom: Constants.DEFAULT_CURRENCY,
          number: this.deposit.barNo,
          brand: this.deposit.brandMark,
          pieces: this.deposit.noOfPieces,
          weight: BigNumber(this.deposit.barWeight)
            .multipliedBy(precision)
            .toFixed(),
          fineWeight: BigNumber(this.deposit.fineWeight)
            .multipliedBy(precision)
            .toFixed(),
          uri: this.deposit.url,
        };
        try {
          const simulateResult = await this.$store.dispatch(
            "metdeal.cryptounce.depository.v1/sendMsgDepositBarSimulate",
            {
              value,
              fee: [{ amount: "0", denom: Constants.DEFAULT_CURRENCY }],
              memo: "",
            }
          );
          this.gas = simulateResult.calculatedGas;

          let baseAmount = BigNumber(this.gas)
            .multipliedBy(BigNumber(Constants.GAS_PRICE))
            .multipliedBy(BigNumber(10).exponentiatedBy(coin.denomExponent()));
          baseAmount = baseAmount.integerValue(BigNumber.ROUND_CEIL);
          this.fee = {
            amount: baseAmount.toString(),
            denom: Constants.DEFAULT_CURRENCY,
          };
        } catch (e) {
          console.error(e);
        }
      }
    },
    resetDeposit: function () {
      this.deposit.barNo = "";
      this.deposit.brandMark = "";
      this.deposit.noOfPieces = "";
      this.deposit.barWeight = "";
      this.deposit.fineWeight = "";
      this.deposit.url = "";
      this.deposit.denom = Constants.DEFAULT_CURRENCY;
      this.gas = 0;
      this.fee = { amount: "0", denom: Constants.DEFAULT_CURRENCY };
    },
  },
});
</script>
